import type { DefineComponent } from 'vue'
import type { SelectOption } from '@/Types/utils'

/** Works like a classic computed but stops triggering updates if the value remains unchanged. */
export function performantComputed<T>(get: () => T) {
  const reference = ref<T>() as Ref<T>
  watch(get, (value) => (reference.value = value), { immediate: true })

  return readonly(reference)
}

/** As opposed to %, this always returns a non-negative result. */
export function modEuclid(n: number, k: number) {
  return ((n % k) + k) % k
}

export function getByPath(object: Record<string, unknown>, path: string): unknown {
  // eslint-disable-next-line unicorn/no-array-reduce
  return path.split('.').reduce((acc, next) => (acc as any)[next], object)
}

export function setByPath(object: Record<string, unknown>, path: string, value: unknown) {
  const segments = path.split('.')
  // eslint-disable-next-line unicorn/no-array-reduce
  segments.slice(0, -1).reduce((acc, next) => (acc as any)[next], object)[
    // eslint-disable-next-line unicorn/prefer-at
    segments[segments.length - 1]
  ] = value
}

export function isInInputContext() {
  const activeElement = document.activeElement
  if (!activeElement) return false

  const isInput = activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA'
  const isContentEditable = activeElement.hasAttribute('contenteditable')
  if (isInput || isContentEditable) return true

  return false
}

export function matchesShallowly<T extends Record<string, unknown>>(a: T, b: T) {
  for (const key in a) {
    if (a[key] !== b[key]) return false
  }
  for (const key in b) {
    if (b[key] !== a[key]) return false
  }
  return true
}

// https://github.com/vuejs/language-tools/issues/3206#issuecomment-1624541884
export type ComponentInstance<T> = T extends new (...args: any[]) => infer R
  ? R
  : T extends (...args: any[]) => infer R
    ? R extends { __ctx?: infer K }
      ? Exclude<K, void> extends { expose: (...args: infer K) => void }
        ? K[0] & InstanceType<DefineComponent>
        : any
      : any
    : any

export function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function downloadUrl(url: string, filename: string) {
  const a = document.createElement('a')
  document.body.append(a)
  a.href = url
  a.setAttribute('download', filename)
  a.click()
  a.remove()
}

export function downloadBlob(blob: Blob, filename: string) {
  const objectUrl = URL.createObjectURL(blob)
  downloadUrl(objectUrl, filename)
  URL.revokeObjectURL(objectUrl)
}

export function parseContentDispositionHeader(header: string): string {
  return header.split('filename=')[1].split(';')[0]
}

export function pickSelectOptions<T extends string>(
  options: SelectOption<T>[],
  values: T[],
): SelectOption<T>[] {
  return options.filter((option) => values.includes(option.value))
}

export function filterInPlace<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
) {
  for (let index = array.length - 1; index >= 0; index--) {
    if (!predicate(array[index], index, array)) array.splice(index, 1)
  }
}

export function assert(x: unknown): asserts x {
  if (!x) {
    throw new Error('Variable should not be falsy')
  }
}

export function assertType<T>(x: NoInfer<T>) {
  // Noop
}

const promiseCache = new Map<string, Promise<any>>()
/**
 * Caches pending and resolved executions of async functions. Can be used to ensure an action is never performed twice.
 * If the operation fails, the cache is cleared again to allow for retries.
 */
export function cacheExecution<T>(key: string, func: () => Promise<T>) {
  if (promiseCache.has(key)) {
    return promiseCache.get(key) as Promise<T>
  }

  const promise = func()
  promiseCache.set(key, promise)
  promise.catch(() => promiseCache.delete(key)) // So it can be retried

  return promise
}
